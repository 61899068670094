.full-width { 
    width: 100%;
}

.category-select-view{
    margin-top: 14px;
}

.category-select-view .MuiFormControl-root {
    margin: 1px 1px 10px 1px;
}

.tabpanels .category-select-view {
    margin-bottom: 15px;
}

.product-list-view .MuiImageListItem-tile{
    text-align: center;
}

.MuiImageListItem-tile{
    border: 1px solid #c4c4c4;
    border-radius: 4px;
}

.scenario-img {
    border: none;
}

img.product-thumb {
    width: 100%;
}