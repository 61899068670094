.scenario-img {
    max-width: 100%;
}

img.scenario-img{
        border: 1px solid #c4c4c4;
        border-radius: 4px;
}

.MuiImageList-root.scenarios-list {
    -webkit-overflow-scrolling: touch;
    flex-wrap: nowrap !important;
    width: 100%;
}

.scenario-view{
    margin-top: 15px;
}

.container-scenarios-list{
    border: 1px solid #c4c4c4;
    border-radius: 4px;
}

.container-scenarios-list .MuiListSubheader-sticky.MuiListSubheader-gutters{
    position: relative;
    top: -25px;
    background-color: white;
    width: 100px;
    line-height: 25px;
    margin: 15px 0px 5px 0;
}

.container-scenarios-list ul.MuiImageList-root.scenarios-list{
    margin-top: -25px !important;
    margin-bottom: 0px !important;
}

.MuiImageListItem-root {
    cursor: pointer;
}

@media (max-width: 600px) {
    .MuiImageListItemBar-title {
        font-size: 0.9rem;
    }
}